<template>
	<div>
		<a href="javascript:history.back()" class="btn btn-outline-secondary mr-2 mb-2" v-if="showBackLink">
			<font-awesome-icon icon="arrow-left"/>
			{{ $t('wizard.prev') }}
		</a>

		<h1>Privacy statement</h1>

		<h1>1. Data Controller&rsquo;s details</h1>

		<table>
			<tbody>
			<tr>
				<td>Name of Data Controller:</td>
				<td><strong>Ready 2 Grow Kereskedelmi &eacute;s Szolg&aacute;ltat&oacute; Korl&aacute;tolt Felelőss&eacute;gű T&aacute;rsas&aacute;g</strong></td>
			</tr>
			<tr>
				<td>Registered office:</td>
				<td><strong>2100 G&ouml;d&ouml;llő, Remsey krt. 8.</strong></td>
			</tr>
			<tr>
				<td>Phone:</td>
				<td><a href="tel:+36303715660"><strong>+36 30 371 5660</strong></a></td>
			</tr>
			<tr>
				<td>Email:</td>
				<td><a href="mailto:info@r2g.hu"><strong>info@r2g.hu</strong></a></td>
			</tr>
			<tr>
				<td>Website:</td>
				<td><a href="https://www.r2g.hu" target="_blank"><strong>www.r2g.hu</strong></a></td>
			</tr>
			<tr>
				<td>Company registration number:</td>
				<td><strong>13-09-177610</strong></td>
			</tr>
			<tr>
				<td>Representative:</td>
				<td><strong>Marianna Maka</strong></td>
			</tr>
			</tbody>
		</table>

		<p>&nbsp;</p>

		<h1>2. Purpose of the information</h1>

		<p>This data processing policy aims to provide data subjects with information in a clear and comprehensive way about processing their personal data.</p>

		<p>The processing of personal data is not one of our core activities, but in carrying it out we pay particular attention to the relevant EU and national legislation, in particular Regulation (EU) 2016/679 of the European Parliament and of the Council of 27 April 2016 on the protection of natural persons with regard to the processing of personal data and on the free movement of such data, and repealing Directive 95/46/EC (General Data Processing Regulation - GDPR) and the provisions of the domestic Act CXII of 2011 on the Right to Informational Self-Determination and on Freedom of Information.</p>

		<p>As regards our professional activities, our company offers live maps with air-traffic and GPS-based flight information services, to the users of PilotNet web-based or mobile applications (referred to as the &ldquo;Services&rdquo;).</p>

		<h1>3. Data processed</h1>

		<p>Depending on your specific mobile device on which you have installed the &ldquo;Services&rdquo;, PilotNet may request certain permissions that allow access to User&#39;s device Data as described below.</p>

		<p>By default, these permissions must be granted by you before the respective information can be accessed. Once permission has been given, you can revoke it at any time. In order to revoke these permissions, you can refer to the device settings.</p>

		<p>The exact procedure for controlling app permissions may depend on your device and software.</p>

		<p>If you use the &ldquo;Services&rdquo;, depending on your personal choices, it may obtain permission to access e.g. email address, account information and your profile, detailed location, flight and route information (for example in the form of GPS signals, combined with a time-stamp), IP address and other information is collected from sensors and receivers on or around your device, on which the &ldquo;Services&rdquo; are installed and activated, location and route information that is saved to a route history of all the journeys you have made while using the &ldquo;Services&rdquo;.</p>

		<p>PilotNet collects information that you have chosen to share, including:</p>

		<ul>
			<li>your phone number, aircraft details, destinations you may travel to, search queries, calendar information if applicable;</li>
			<li>your activity as a pilot or passenger</li>
			<li>We may also collect information when you exchange communication with PilotNet, for example, if you submit a request, contact PilotNet&rsquo;s support team.</li>
		</ul>

		<p><strong>Meta-data (information about your device, browser and app use):</strong></p>

		<p>PilotNet collects information about the use of the &ldquo;Services&rdquo; and information from the device onto which you have PilotNet installed or the browser from which you use the &ldquo;Services&rdquo;, e.g.:</p>

		<ul>
			<li>PilotNet may collect and record how often you use our &ldquo;Services&rdquo; and for how long, your device type, unique identifiers, operating system type &amp; version, battery usage, the third party web pages or applications you visit or use or interact with via the &ldquo;Services&rdquo;, information that you viewed on our &ldquo;Services&rdquo;, the Internet Protocol (IP) address and the name of the domain that serves you to access the &ldquo;Services&rdquo;, and the geographic location of the device that you are using to login and use PilotNet on.</li>
			<li>PilotNet also collects information about the interaction of your devices with our &ldquo;Services&rdquo;, including crash reports, system activity, and the date, time, and referrer URL of your request.</li>
		</ul>

		<h1>4. General purpose of data processing</h1>

		<p>The purpose of processing</p>

		<ul>
			<li>to allow PilotNet to provide our &ldquo;Services&rdquo;, e.g. traffic display, and all information that can help to enhance your situational awareness when flying</li>
			<li>to allow PilotNet to maintain and ensure our &ldquo;Services&rdquo; are working as intended, such as tracking outages or troubleshooting issues you report to us. And we use your information to improve the service or to develop new features or &ldquo;Services&rdquo;.</li>
			<li>to develop new &ldquo;Services&rdquo; and features that are useful for our users</li>
			<li>to understanding how people use our &ldquo;Services&rdquo; to ensure and improve the performance of our &ldquo;Services&rdquo;</li>
		</ul>

		<h1>5. Security of data</h1>

		<p>We ensure the security of the personal data we process by implementing technical and organizational measures and procedures.</p>

		<p>We take appropriate measures to protect the data against unauthorized access, alteration, disclosure, disclosure, deletion or destruction, accidental destruction or damage, and against inaccessibility resulting from changes in the technology used.</p>

		<p>Access to personal data is restricted to those employees who need to know it in order to perform their duties.</p>

		<p>To ensure the security of your data:</p>

		<ul>
			<li>we assess and take into account potential risks in the design and operation of the IT system, and seek to mitigate them continuously</li>
			<li>monitor emerging threats and vulnerabilities (such as computer viruses, computer intrusions, denial of service attacks, etc.) to take timely action to avoid and prevent them</li>
			<li>protect IT assets and information on paper against unauthorized physical access and environmental impacts (e.g. water, fire, electrical surges)</li>
			<li>monitor our IT systems to detect potential problems and incidents</li>
			<li>we take great care to train staff in information security and raise awareness</li>
			<li>reliability is a key criterion in the choice of service providers</li>
		</ul>

		<h1>6. Transmission, transfer of data</h1>

		<p>Data may be transferred in the event of a request from a public authority.</p>

		<p>PilotNet only stores location data that are aggregated as anonymized contents, with the aim of helping us and third parties to collect general information about General Aviation (GA) e.g. the evolution of GA activity, by region, time, duration, frequency, trajectories of flights, type of aircraft etc. The resulting data may be shared, sold or licensed to third parties, e.g. for statistical purposes, for instance, to study the impact of General Aviation (GA) or use of airspace as well as to enhance traffic awareness throughout the GA community.</p>

		<p>PilotNet might share anonym data to Google (<a href="https://policies.google.com/technologies/partner-sites">https://policies.google.com/technologies/partner-sites</a>) with the aim of analitics and usage monitoring of our system.</p>

		<h1>7. Retention time of personal data</h1>

		<p>PilotNet holds personal data for at least as long as we provide &ldquo;Services&rdquo; to you. When you decide not to use our &ldquo;Services&rdquo; any longer, or when subscription expires, your data held will be deleted after 5 years.</p>

		<h1>8. Rights of data subjects</h1>

		<p>All data subjects have the right to:</p>

		<ul>
			<li>To be informed of the data processing concerning him or her before they start</li>
			<li>Have access to all information about data processing concerning him or her</li>
			<li>Request the correction of incorrect, inaccurate or incomplete data</li>
			<li>Request the deletion (inactivation) of his/her personal data</li>
			<li>Request restriction of data processing</li>
			<li>Object to the use of his/her data in certain cases, for example for marketing purposes</li>
			<li>To exercise remedy against processing of personal data</li>
		</ul>

		<p>These rights can be exercised by the data subject in writing using the contact details provided below, or in person by prior arrangement. We will endeavor to respond to all requests as soon as possible, but not later than 15 working days.</p>

		<p>Contact details for exercising your rights:</p>

		<ul>
			<li>By post: Ready 2 Grow Kereskedelmi &eacute;s Szolg&aacute;ltat&oacute; Korl&aacute;tolt Felelőss&eacute;gű T&aacute;rsas&aacute;g, 2100 G&ouml;d&ouml;llő, Remsey krt. 8.</li>
			<li>By e-mail: <a href="mailto:info@pilotnet.hu">info@pilotnet.hu</a></li>
			<li>In person: <a href="tel:+36303715660">+36 30 371 5660</a> by telephone as agreed.</li>
		</ul>

		<p>We are unable to give out personal information via a phone call as we cannot identify the caller.</p>

		<p>In the event of a breach of their rights, data subjects may refer the matter to the National Authority for Data Protection and Freedom of Information:</p>

		<ul>
			<li>Address: 1125 Budapest, Szil&aacute;gyi Erzs&eacute;bet fasor 22/c</li>
			<li>Phone: +36 (1) 391-1400</li>
			<li>Fax: +36 (1) 391-1410</li>
			<li>Website: <a href="http://www.naih.hu" target="_blank">http://www.naih.hu</a></li>
			<li>E-mail: ugyfelszolgalat@naih.hu</li>
		</ul>

	</div>
</template>

<script>
export default {
	name: "PrivacyStatementView",
	data() {
		return {
			showBackLink: history.length > 1
		}
	},
	methods: {
		scrollTo(refName) {
			let element = this.$refs[refName];
			let top = element.offsetTop;
			window.scrollTo(0, top);
		}
	}
}
</script>

<style scoped>
table {
	border-top: 1px solid #000000;
	border-left: 1px solid #000000;
	border-spacing: 0;
	width: 100%;
	margin-bottom: 1rem;
}

td, th {
	border-bottom: 1px solid #000000;
	border-right: 1px solid #000000;
	padding: 5px;
	text-align: left;
}

tbody th {
	font-weight: normal;
}

ol {
	counter-reset: item;
}

ol > li {
	counter-increment: item;
}

ol ol > li {
	display: block;
}

ol ol > li:before {
	content: counters(item, ".") ". ";
	margin-left: -20px;
}
</style>
